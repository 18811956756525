.configuration-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.configuration-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  padding-left: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  justify-content: flex-start;
}
.configuration-text {
  align-self: center;
}
.configuration-container2 {
  width: 100%;
  height: 477px;
  display: flex;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  justify-content: center;
}
.configuration-container3 {
  flex: 0 0 auto;
  width: 206px;
  display: flex;
  align-items: flex-start;
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.configuration-profile {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.configuration-image {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.configuration-container4 {
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  flex-direction: column;
  justify-content: space-between;
}
.configuration-text1 {
  font-size: 1.15rem;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 0.25rem;
}
.configuration-text2 {
  color: var(--dl-color-gray-500);
  font-size: 0.75rem;
}
.configuration-container5 {
  flex: 1;
  width: 100%;
  height: 377px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-primary-700);
  border-width: 0px;
  padding-left: var(--dl-space-space-twounits);
  flex-direction: column;
  border-left-width: 3px;
}
.configuration-form {
  flex: 1;
  height: 100px;
  display: flex;
  flex-direction: column;
}
.configuration-container6 {
  gap: 20;
  width: 100%;
  height: 47px;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: flex-start;
}
.configuration-text3 {
  align-self: flex-start;
}
.configuration-container7 {
  gap: 20;
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.configuration-container8 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
}
.configuration-navlink {
  color: var(--dl-color-gray-white) !important;
  text-decoration: none;
  background-color: var(--dl-color-danger-300) !important;
}
.configuration-navlink1 {
  color: var(--dl-color-gray-white) !important;
  text-decoration: none;
  background-color: var(--dl-color-primary-300) !important;
}
@media(max-width: 991px) {
  .configuration-profile {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }
  .configuration-image {
    margin-left: 0px;
  }
}
@media(max-width: 479px) {
  .configuration-container2 {
    flex: 1;
    flex-direction: column;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .configuration-container3 {
    align-self: flex-start;
  }
  .configuration-container5 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: 0px;
    border-top-width: 3px;
    border-left-width: 0px;
  }
  .configuration-container8{
    justify-content: center;
  }
  .configuration-form{
    width: 100%;
  }
}
