.testimonial-card1-testimonial-card {
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxwidth);
  background: #fff;
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.testimonial-card1-icon {
  width: 2rem;
  margin-bottom: var(--dl-space-space-twounits);
}
.testimonial-card1-testimonial {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.testimonial-card1-text {
  color: var(--dl-color-gray-500);
  text-align: center;
  margin-bottom: var(--dl-space-space-threeunits);
}
.testimonial-card1-text1 {
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-halfunit);
}
.testimonial-card1-text2 {
  color: var(--dl-color-gray-500);
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unit);
  text-transform: uppercase;
}
.testimonial-card1-image {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}



@media(max-width: 991px) {
  .testimonial-card1-text2 {
    align-self: center;
  }
}
@media(max-width: 767px) {
  .testimonial-card1-testimonial-card {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .testimonial-card1-testimonial-card {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .testimonial-card1-icon {
    margin-bottom: var(--dl-space-space-unit);
  }
  .testimonial-card1-text {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .testimonial-card1-text1 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .testimonial-card1-text2 {
    margin-bottom: var(--dl-space-space-unit);
  }
}
