.reader-template-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.reader-template-container1 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.reader-template-text {
  align-self: center;
}
.reader-template-navlink {
  color: var(--dl-color-gray-white) !important;
  align-self: flex-start;
  text-decoration: none;
  background-color: var(--dl-color-success-300) !important;
}
.reader-template-container2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  justify-content: center;
}
.reader-template-image {
  width: 478px;
  object-fit: cover;
}
.reader-template-container3 {
  width: 100%;
  display: flex;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: center;
}
.reader-template-button {
  display: none !important;
  height: 61px;
  align-self: center;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-round);
  line-height: 0px;
}
.reader-template-text01 {
  font-size: 50px;
}
.reader-template-text04 {
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  padding-bottom: var(--dl-space-space-twounits);
}
.reader-template-text04 p{
  text-align: justify;
}
.reader-template-button1 {
  display: none !important;
  height: 61px;
  align-self: center;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-round);
  line-height: 0px;
}
.reader-template-text10 {
  font-size: 50px;
}
@media(max-width: 991px) {
  .reader-template-navlink {
    top: 54px;
    left: 253px;
  }
}
@media(max-width: 767px) {
  .reader-template-navlink {
    top: 55px;
    left: 145px;
  }
  .reader-template-container1{
    flex-direction: column;
    gap: 0
  }
  .button-container{
    margin: 0 auto;
  }
}
@media(max-width: 479px) {
  .reader-template-text {
    width: 331px;
    padding: var(--dl-space-space-unit);
    text-align: center;
  }
  .reader-template-navlink {
    top: 116px;
    left: 178px;
  }
  .reader-template-image {
    width: 264px;
  }
  .reader-template-container3 {
    padding: 0px;
    padding-top: 0px;
    padding-left: 0px;
    margin-bottom: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  }
  .reader-template-button {
    position: relative;
    line-height: 0px;
  }
  .reader-template-text01 {
    right: 0px;
    bottom: -5px;
    position: absolute;
  }
  .reader-template-text04 {
    height: auto;
    padding: var(--dl-space-space-unit);
    align-self: flex-start;
    text-align: justify;
  }
  .reader-template-button1 {
    position: relative;
    line-height: 0px;
  }
  .reader-template-text10 {
    left: 0px;
    bottom: -5px;
    position: absolute;
  }
}
.button-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: var(--dl-space-space-unit);
}
.errase{
  background-color: var(--dl-color-danger-300) !important;
  color: var(--dl-color-gray-white) !important;
  align-self: flex-start;
}