.list-item-container {
  display: flex;
  position: relative;
  flex-direction: column;
}
.list-item-li {
  margin-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-primary-700);
  border-width: 0px;
  margin-bottom: var(--dl-space-space-unit);
  border-left-width: 0px;
  border-bottom-width: 3px;
}
.list-item-text {
  font-size: 20px;
}
