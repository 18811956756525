.newBook {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 0 auto;
  margin-bottom: var(--dl-space-space-twounits);
}
.container {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  gap: var(--dl-space-space-twounits);
  border-bottom: 1px solid var(--dl-color-gray-700);
}

@media(max-width: 479px) {
  .container {
    gap: var(--dl-space-space-twounits);
    flex: 1;
    margin-top: var(--dl-space-space-twounits);
    padding: 0 var(--dl-space-space-twounits) var(--dl-space-space-twounits);
    justify-content: center;
  }
  .button {
    font-size: 16px;
  }
  .newBook{
    width: 80%;
  }
}

.button:hover{
  background-color: var(--dl-color-gray-700);
}