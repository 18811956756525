.login-container {
  width: 100%;
  display: flex;
  overflow: auto;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.login-container1 {
  width: 100%;
  height: 497px;
  display: flex;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-image: url("https://images.unsplash.com/photo-1674059393000-15296402cd36?ixid=Mnw5MTMyMXwwfDF8YWxsfDJ8fHx8fHwyfHwxNjc0MTk4NDMx&ixlib=rb-4.0.3&w=1500");
}
.login-container2 {
  width: 368px;
  height: 366px;
  display: flex;
  box-shadow: 0px 0px 10px 5px #696464;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.login-text {
  align-self: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.login-form {
  width: 373px;
  height: 203px;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.login-container3 {
  gap: var(--dl-space-space-unit);
  width: 253px;
  height: 168px;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
}
.login-container4 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  height: 58px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.login-navlink {
  color: var(--dl-color-gray-white);
  text-decoration: none;
  background-color: var(--dl-color-primary-300);
}
.login-navlink1 {
  color: var(--dl-color-gray-white);
  text-decoration: none;
  background-color: var(--dl-color-primary-300);
}
div#firebase-auth-container {
  justify-content: center;
  width: 100%;
}