.nav-container {
  width: 100%;
  height: 83px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #489bd2;
}
.nav-container *{
  transform: 1s;
}
.nav-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.nav-navbar-interactive {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
  background-color: var(--dl-color-primary-100);
  z-index: 2;
}
.nav-logo {
  height: 4rem;
}
.nav-burger-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.nav-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.nav-nav {
  height: 395px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.nav-container2 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.nav-image {
  height: 4rem;
}
.nav-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-icon2 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.nav-nav1 {
  width: 205px;
  height: 210px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-black);
  border-style: solid;
  padding-left: 0px;
  padding-right: 0px;
  row-gap: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.nav-profile {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.nav-image1 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.nav-container3 {
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  flex-direction: column;
  justify-content: space-between;
}
.nav-text {
  font-size: 1.15rem;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 0.25rem;
}
.nav-text1 {
  color: var(--dl-color-gray-500);
  font-size: 0.75rem;
}
.nav-navlink {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.nav-navlink1 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.nav-navlink2 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.nav-container4 {
  flex: 0 0 auto;
  width: 203px;
  height: 66px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.nav-container5 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.nav-container6 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
}
.nav-login {
  text-decoration: none;
}
.nav-root-class-name {
  color: var(--dl-color-gray-black);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: #D9D9D9;
}




@media(max-width: 1200px) {
  .nav-burger-menu {
    display: flex;
  }
}
@media(max-width: 991px) {
  .nav-profile {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }
  .nav-image1 {
    margin-left: 0px;
  }
}
@media(max-width: 767px) {
  .nav-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .nav-container {
    background-color: transparent;
  }
  .nav-container1 {
    background-color: var(--dl-color-primary-300);
  }
  .nav-navbar-interactive {
    padding: var(--dl-space-space-unit);
    background-color: transparent;
  }
  .nav-mobile-menu {
    padding: 16px;
  }
}
