.feature-card3-feature-card {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
}
.feature-card3-icon {
  fill: var(--dl-color-gray-500);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
}
.feature-card3-text {
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.feature-card3-text1 {
  color: var(--dl-color-gray-700);
  text-align: center;
}
.feature-card3-navlink {
  font-size: 0.75rem;
  margin-top: var(--dl-space-space-unit);
  font-weight: 500;
  text-decoration: none;
}




