.check-container {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: 100%;
  height: 41px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
}
.check-text {
  font-size: 20px;
}
@media(max-width: 479px){
  .check-container{
    justify-content: center;
  }
}