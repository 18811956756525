.modal{
    display: flex;
    width: 100%;
    height: 100vh;
    position: fixed;
    justify-content: center;
    align-items: center;
    top:0;
    right: 0;
    background-color: rgba(0, 0, 0, .8);
    z-index: 2;
}

.modal-container{
    background-color: var(--dl-color-gray-white);
    padding: var(--dl-space-space-fourunits);
    display: flex;
    flex-direction: column;
    text-align: center;
    row-gap:  var(--dl-space-space-unit);
    border-radius: var(--dl-radius-radius-radius4);
    box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.5);
    width: 50%;
}
.modal-body{
    display: flex;
    flex-direction: row;
    justify-content:space-evenly;
}
@media(max-width: 479px){
    .modal-container{
        width: 90%;
    }
    .modal-body{
        column-gap: var(--dl-space-space-halfunit);;
    }
}