.gallery-card1-gallery-card {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.gallery-card1-image {
  width: 50%;
  height: 50%;
  object-fit: cover;
}
.gallery-card1-container {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.gallery-card1-text {
  align-self: center;
  margin-top: var(--dl-space-space-halfunit);
  font-weight: 600;
  margin-bottom: var(--dl-space-space-halfunit);
}
.gallery-card1-text1 {
  color: var(--dl-color-gray-500);
  font-size: 12px;
  align-self: center;
  margin-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
}
.gallery-card1-text2 {
  color: var(--dl-color-gray-500);
  align-self: center;
}
.gallery-card1-container1 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.gallery-card1-read {
  color: var(--dl-color-gray-white) !important;
  text-decoration: none;
  background-color: var(--dl-color-primary-300) !important;
}
.gallery-card1-configure {
  color: var(--dl-color-gray-white) !important;
  text-decoration: none;
  background-color: var(--dl-color-primary-500) !important;
}




@media(max-width: 767px) {
  .gallery-card1-gallery-card {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .gallery-card1-image {
    height: 10rem;
  }
  .gallery-card1-container1 {
    gap: var(--dl-space-space-unit);
  }
  .gallery-card1-read {
    padding: var(--dl-space-space-halfunit);
    font-size: 14px;
  }
  .gallery-card1-configure {
    padding: var(--dl-space-space-halfunit);
    font-size: 14px;
  }
  .gallery-card1-text{
    text-align: center;
  }
}
