.info-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.info-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-gray-black);
}
.info-hero {
  width: 100%;
  height: 612px;
  display: flex;
  opacity: 1;
  padding: var(--dl-space-space-threeunits);
  position: relative;
  min-height: var(--dl-size-size-small);
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-color: #D9D9D9;
  background-image: url("https://images.unsplash.com/photo-1506880018603-83d5b814b5a6?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDF8fHJlYWRpbmd8ZW58MHx8fHwxNjc0MTk4NDM2&ixlib=rb-4.0.3&w=1500");
  background-repeat: no-repeat;
  background-position: center;
}
.info-container2 {
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
}
.info-text {
  color: var(--dl-color-gray-white);
  font-size: 3rem;
  max-width: 25rem;
}
.info-text01 {
  color: var(--dl-color-gray-white);
  font-size: 1.15rem;
  max-width: 60%;
  margin-top: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-threeunits);
}
.info-container3 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
}
.info-navlink {
  color: var(--dl-color-gray-white) !important;
  font-style: normal;
  text-align: center;
  transition: 0.3s;
  font-weight: 400;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  margin-right: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-primary-300) !important;
}
.info-navlink:hover {
  transform: scale(1.02);
}
.info-navlink1 {
  color: var(--dl-color-gray-white) !important;
  font-style: normal;
  text-align: center;
  transition: 0.3s;
  font-weight: 400;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  margin-right: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-primary-300) !important;
}
.info-navlink1:hover {
  transform: scale(1.02);
}
.info-features {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  align-items: center;
  flex-direction: column;
}
.info-text06 {
  font-size: 3rem;
  margin-bottom: var(--dl-space-space-twounits);
}
.info-text07 {
  color: var(--dl-color-gray-700);
  width: 70%;
  font-size: 1.15rem;
  text-align: center;
}
.info-container4 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  margin-top: var(--dl-space-space-fourunits);
  grid-template-columns: 1fr 1fr 1fr;
}
.info-testimonial {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: 232323;
}
.info-container5 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.info-text10 {
  margin-bottom: var(--dl-space-space-twounits);
}
.info-text13 {
  color: var(--dl-color-gray-white);
  font-size: 0.75rem;
  max-width: 600px;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.info-container6 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  grid-template-columns: 1fr 1fr 1fr;
}
@media(max-width: 991px) {
  .info-text06 {
    text-align: center;
  }
  .info-text07 {
    text-align: center;
  }
  .info-container4 {
    grid-template-columns: 1fr 1fr;
  }
  .info-text10 {
    text-align: center;
  }
  .info-text13 {
    text-align: center;
  }
  .info-container6 {
    grid-template-columns: 1fr;
  }
}
@media(max-width: 767px) {
  .info-hero {
    align-items: center;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .info-text {
    text-align: center;
  }
  .info-text01 {
    max-width: 100%;
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .info-features {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .info-text06 {
    text-align: center;
  }
  .info-container5 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .info-hero {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .info-text {
    align-self: center;
  }
  .info-text01 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .info-container3 {
    gap: var(--dl-space-space-twounits);
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  .info-navlink {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .info-navlink1 {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .info-features {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .info-container4 {
    grid-template-columns: 1fr;
  }
  .info-container5 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
}
div#home::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0,0,0,.5);
  z-index: 0;
}