.load span {
   display: block;
      width: 70px;
      height: 70px;
      border: 3px solid;
      border-radius: 50%;
      border-color: transparent #000 #000;
      animation: cssload-spin 690ms infinite linear;
  }

  @keyframes cssload-spin {
    100% {
      transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

.load{
  display: flex;
  position: fixed;
  background-color: rgba(0, 0, 0, .5);
  width: 100%;
  top: 0;
  right: 0;
  height: 100vh;
  z-index: 2;
  justify-content: center;
  align-items: center;
}