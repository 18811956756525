.input-container {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
}
.input-text {
  font-size: 20px;
}
@media(max-width: 479px){
  .input-container{
    flex-direction: column;
    gap: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-halfunit);;
  }
}