.notLogedIn {
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    background-image: url("https://images.unsplash.com/photo-1674059393000-15296402cd36?ixid=Mnw5MTMyMXwwfDF8YWxsfDJ8fHx8fHwyfHwxNjc0MTk4NDMx&ixlib=rb-4.0.3&w=1500");
}
.box{
    padding: var(--dl-space-space-twounits);
    border-radius: 5px;
    box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
    display: flex;
    row-gap: var(--dl-space-space-halfunit);
    flex-direction: column;
    background-color: var(--dl-color-gray-white);
}
.notLogedIn .box span{
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: var(--dl-space-space-halfunit);
}
